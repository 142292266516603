import React, { Component } from "react";
import styled from "@emotion/styled";
/*import colors from "styles/colors";*/
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import RichText from 'components/RichText';
import Content from 'components/_ui/Content';

const AboutIntroContainer = styled(Inner)`
  & + * {
    margin-top: 8.8rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 12.8rem;
    } 
  }
`;

const AboutIntroContent = styled.div`
  h1, h2, h3 {
    margin-bottom: 2.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-left: 41.6667%;
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    padding-right: 10.4rem;
  }
`;

class AboutIntro extends Component {
  render() {
    return (
      <AboutIntroContainer>
        <AboutIntroContent>
          {this.props.heading && <RichText render={this.props.heading}/>}
          {this.props.content && <Content content={this.props.content}/>}
        </AboutIntroContent>
      </AboutIntroContainer>
    );
  }
}

export default AboutIntro;

import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/SEO';
import Layout from 'components/Layout';
import AboutHeader from 'components/_page/about/Header';
import AboutIntro from 'components/_page/about/AboutIntro';
import Values from 'components/_page/about/Values';
import Stats from 'components/_page/about/Stats';
import Clients from 'components/_page/about/Clients';
import GetInTouch from 'components/GetInTouch';
import Related from 'components/Related';

const RenderBody = ({ about, history, careers, site }) => (
  <>
    <AboutHeader
      title='About Us'
      heading={about.introduction_sections[0].heading}
      content={about.introduction_sections[0].content}
      parallax={[-80, 100]}
    />

    {about.introduction_sections.map((section, i) => {
      if (i !== 0) {
        return (
          <AboutIntro
            heading={section.heading}
            content={section.content}
          />
        );
      }

      return null;
    })}

    {about.values.length > 0 && <Values values={about.values}/>}

    {about.stats.length > 0 && (
      <Stats
        stats={about.stats}
        heading={about.stats_heading}
        content={about.stats_content}
      />
    )}

    {about.clients.length > 0 && (
      <Clients
        heading={about.clients_heading}
        clients={about.clients}
        areas={site.featured_industries}
      />
    )}

    <GetInTouch
      title={about.get_in_touch_heading || site.get_in_touch_heading || 'When you’re facing challenges, you need to know who’s on your side.'}
    />

    <Related
      heading='Learn more about us'
      type='trapdoor'
      cards={[
        {
          destination: '/history',
          image: history.feature_image,
          name: 'History',
          description: history.short_description,
        },
        {
          destination: '/careers',
          image: careers.feature_image,
          name: 'Careers',
          description: careers.short_description,
        },
      ]}
    />
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const about = data.prismic.allAbouts.edges[0].node;
  const history = data.prismic.allHistorys.edges[0].node;
  const careers = data.prismic.allCareerss.edges[0].node;
  const site = data.prismic.allSite_settingss.edges[0].node;

  if (!about) return null;

  return (
    <Layout>
      <SEO
        title={about.social_title || 'About Us'}
        description={about.social_description ? about.social_description : null}
        image={about.social_image ? about.social_image.url : null}
      />

      <RenderBody
        about={about}
        history={history}
        careers={careers}
        site={site}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allAbouts {
        edges {
          node {
            introduction_sections {
              heading
              content
            }
            values {
              heading
              text
              image
            }
            stats_heading
            stats_content
            stats {
              value
              label
            }
            clients_heading
            clients {
              name
              area {
                ... on PRISMIC_Area {
                  name
                  _meta {
                    uid
                  }
                }
              }
              image
              case_study {
                ... on PRISMIC_Case_study {
                  _meta {
                    uid
                  }
                  logo
                  client_name
                  area {
                    ... on PRISMIC_Area {
                      name
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
            }
            get_in_touch_heading
            social_title
            social_description
            social_image
          }
        }
      }
      allHistorys {
        edges {
          node {
            feature_image
            short_description
          }
        }
      }
      allCareerss {
        edges {
          node {
            feature_image
            short_description
          }
        }    
      }
      allSite_settingss {
        edges {
          node {
            get_in_touch_heading
            featured_industries {
              area {
                ... on PRISMIC_Area {
                  name
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from "styles/structure";
import RichText from 'components/RichText';
import Content from 'components/_ui/Content';
import Zoom from 'react-reveal/Zoom';

const StatsContainer = styled.div`
  & + * {
    margin-top: 12.8rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 16rem;
    }  
  }
`;

const StatsInner = styled(Inner)`
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const StatsContent = styled.div`
  margin-bottom: 6.4rem;
  max-width: 62.8rem;
  
  > div {
    margin-top: 2.4rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 50%;
    order: 2;
    margin-bottom: 0;
    margin-left: 5.6vw;
    max-width: none;
  }
`;

const StatsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 62.8rem;
  
  li {
    flex: 1 1 50%;
    
    &:nth-of-type(n+3) {
      margin-top: 4rem;
    }  
  }
  
  h2 {
    color: ${colors.red600};
  }
  
  p {
    font-weight: 600;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 2 1 70%;
    
    p {
      font-size: 2.4rem;
      font-weight: 500;
    }
    
    li {
      div {
        padding: 0 1.6rem;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 7.2rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    h2 {
      font-size: 8rem;
    }
    
    li {
      div {
        padding: 0 3.2rem;
      }
    }
  }
`;

class Stats extends Component {
  render() {
    return (
      <StatsContainer>
        <StatsInner>
          {(this.props.content || this.props.heading) && (
            <StatsContent>
              {this.props.heading && <RichText render={this.props.heading}/>}
              {this.props.content && <Content content={this.props.content}/>}
            </StatsContent>
          )}

          {this.props.stats.length > 0 && (
            <StatsList>
              {this.props.stats.map((stat, i) => (
                <Zoom delay={50*i}>
                  <li key={i}>
                    <div>
                      {stat.value && <h2>{stat.value}</h2>}
                      {stat.label && <p>{stat.label}</p>}
                    </div>
                  </li>
                </Zoom>
              ))}
            </StatsList>
          )}
        </StatsInner>
      </StatsContainer>
    );
  }
}

export default Stats;

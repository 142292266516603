/* eslint-disable jsx-a11y/no-onchange */

import React from 'react';
import styled from '@emotion/styled';
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import Caret from '../../../vectors/caret-down.svg';

const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  
  svg {
    display: block;
    position: absolute;
    top: 50%;
    right: 2.4rem;
    width: 1.5rem;
    height: 0.8rem;
    fill: ${colors.red600};
    transition: transform 0.08s ease-in-out;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }

  select {
    appearance: none;
    border: none;
    background: none;
    position: relative;
    color: ${colors.red600};
    font-size: 1.8rem;
    font-weight: 600;
    padding-right: 5.6rem;
    height: 4.8rem;
    
    &::-ms-expand {
      display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {    
    display: none;
  }
`;

const NavSelect = (props) => {
  return (
    <SelectContainer className={props.variant ? `Select--${props.variant}` : ''}>
      <select
        onChange={props.handleSelectChange}
        value={props.default || props.value || 'all'}
      >
        <option value='all' >All Clients</option>

        {props.options.map(({area}, i) => (
          <option key={i} value={area.name}>{area.name}</option>
        ))}
      </select>

      <Caret/>
    </SelectContainer>
  );
};

export default NavSelect;

import React, { Component } from "react";
import styled from "@emotion/styled";
/*import colors from "styles/colors";*/
import dimensions from "styles/dimensions";

const ValuesContainer = styled.div`
  padding: 0 2.4rem;
  max-width: 62.8rem;
  margin-left: auto;
  margin-right: auto;
  
  li {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    
    &::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }
    
    & + * {
      margin-top: 2.4rem;
    }
  }

  & + * {
    margin-top: 12.8rem;
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    ul {
      width: 100%;
      max-width: 160rem;
      margin: 0 auto;
    }
    
    & + * {
      margin-top: 20rem;
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    padding: 0;
    max-width: none;
    
    ul {
      display: flex;
      justify-content: center;
    }
    
    li {
      flex: 0 0 33.3333334%;
      
      &:hover {
        img {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.24s ease-in, visibility 0s 0s;
        }
      }
      
      & + * {
        margin-top: 0;
      }
    }
  }
`;

const ValueBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  @media(min-width: ${dimensions.desktopUp}px) {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease-in-out, visibility 0s 0.1s;
  }
`;

const ValueContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > * {
    position: relative;
    z-index: 10;
  }
  
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 4rem;
    left: 4rem;
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
    background-color: #D32030;
    opacity: 0.5;
  }
  
  div {
    isolation: isolate;
  }
  
  h3 {
    color: #fff;
    
    & + * {
      margin-top: 2.4rem;
    }
  }
  
  p {
    color: #fff;
    font-size: 2rem;
    line-height: 1.44;
  }
  
  @supports(mix-blend-mode: multiply) {
    &::before {
      mix-blend-mode: multiply;
      opacity: 1;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: calc(5.5vw + 3.2rem);
    
    &::before {
      top: 5.5vw;
      left: 5.5vw;
      width: calc(100% - 11vw);
      height: calc(100% - 11vw);
    }
  }
`;

class Values extends Component {
  render() {
    return (
      <ValuesContainer>
        <ul>
          {this.props.values.map((value, i) => (
            <li key={i}>
              {value.image && (
                <ValueBackground
                  src={value.image.url}
                  alt={value.image.alt}
                />
              )}

              <ValueContent>
                <div>
                  {value.heading && <h3>{value.heading}</h3> }
                  {value.text && <p>{value.text}</p> }
                </div>
              </ValueContent>
            </li>
          ))}
        </ul>
      </ValuesContainer>
    );
  }
}

export default Values;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner, gridColumns } from 'styles/structure';
import RichText from 'components/RichText';
import NavSelect from 'components/_page/about/NavSelect';
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import getParameter from 'get-parameter';

const ClientsContainer = styled.div`
  header {
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  & + * {
    margin-top: 8rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      padding: 0 4.4445vw;
      
      & + * {
        margin-top: 7.2rem;
      }
    }
  
    & + * {
      margin-top: 12rem;
    }
  }
`;

const ClientsWrapper = styled.div`
  position: relative;
  padding: 3.2rem 0 7.2rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 6.4rem 6.4rem 11.2rem;
    
    &::before {
      width: 100%;
      left: 0;
      transform: translateX(0);
    }
  }
`;

const ClientsFilter = styled.div`
  display: block;
  border-bottom: 1px solid transparent;
  padding-bottom: 1.6rem;
  transition: border 0.12s ease-in-out, padding 0.12s ease-in-out;
  
  .is-filtered & {
    border-bottom-color: ${colors.grey400};
    padding-bottom: 4rem;
    margin-bottom: 6.4rem;
    
    button {
      opacity: 0.3;
      color: #000;
    }
  }
  
  &::before {
    content: 'View Clients by Industry';
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 2.4rem;
  }
  
  ul {
    display: none;
  }
  
  button {
    color: ${colors.red600};
    font-size: 1.8rem;
    font-weight: 500;
    transition: opacity 0.12s ease-in-out, color 0.08s ease-in-out;
    cursor: pointer;
    
    &.is-active {
      opacity: 1;
      color: ${colors.red600};
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    
    li {
      ${gridColumns('1/2', 3.2)}
      
      margin-right: 3.2rem;
      
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 1.6rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {
    li {
      ${gridColumns('1/3', 3.2)}
      
      &:nth-of-type(2n) {
        margin-right: 3.2rem;
      }
      
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 0;
      }
      
      &:nth-of-type(n+4) {
        margin-top: 1.6rem;
      }
    }
  }
`;

const ClientsList = styled.ul`
  display: block;
  width: 100%;
  
  li {
    display: none;
    
    & + * {
      margin-top: 1.6rem;
    }
    
    &.is-active {
      display: block;
    }
    
    div {
      width: 100%;
    }
  }
  
  img {
    display: block;
    width: 100%;
    max-width: 20.4rem;
    margin: 0 auto;
  }
  
  span {
    font-size: 1.8rem;
    transition: opacity 0.12s ease-in-out;
    
    &.is-active {
      opacity: 1 !important;
    }
  }
  
  .is-filtered & {
    span {
      opacity: 0.3;
    }
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    li {
      ${gridColumns('1/2', 0)}
      
      display: none;
      padding: 1.6rem;
      font-size: 2rem;
      
      &.is-active {
        display: flex;
      }
      
      & + * {
        margin-top: 0;
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    li {
      ${gridColumns('1/3', 0)}
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    li {
      ${gridColumns('1/4', 0)}
    }
  }
`;

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      area: (typeof window !== "undefined" && window.document) ? getParameter('clients') : ''
    };
  }

  setActiveArea(area) {
    const newArea = area === this.state.area ? '' : area;

    this.setState({
      area: newArea
    });
  }

  handleSelectChange = (e) => {
    if (e.target.value === 'all') {
      this.setState({
        area: ''
      });
    }
     else {
      this.setActiveArea(e.target.value);
    }
  };

  render() {
    const self = this;

    return (
      <ClientsContainer id='all-clients'>
        <Inner>
          {this.props.heading && (
            <header>
              <RichText render={this.props.heading}/>
            </header>
          )}

          <ClientsWrapper className={self.state.area !== '' ? 'is-filtered': ''}>
            <ClientsFilter>
              <NavSelect
                handleSelectChange={self.handleSelectChange}
                options={this.props.areas}
                value={this.state.area === '' ? 'all' : this.state.area}
              />

              <ul>
                {this.props.areas.map(({area}, i) => (
                  <li key={i}>
                    <button
                      onClick={() => {self.setActiveArea(area._meta.uid)}}
                      className={area._meta.uid === self.state.area ? 'is-active': ''}
                    >
                      {area.name}
                    </button>

                  </li>
                ))}
              </ul>
            </ClientsFilter>

            <ClientsList>
              {this.props.clients.map((client, i) => {
                if (client.case_study && client.case_study.area && client.case_study.logo) {
                  return (
                    <li key={i} className={client.case_study.area._meta.uid === self.state.area ? 'is-active': ''}>
                      <Fade bottom distance='1.6rem'>
                        <Link to={`/study/${client.case_study._meta.uid}`}>
                          <img src={client.case_study.logo.url} alt={client.case_study.logo.alt || client.name || client.case_study.client_name || ''} />
                        </Link>
                      </Fade>
                    </li>
                  )
                } else if (client.area && client.image) {
                  return (
                    <li key={i} className={client.area._meta.uid === self.state.area ? 'is-active': ''}>
                      <Fade bottom distance='1.6rem'>
                        <div>
                          <img src={client.image.url} alt={client.image.alt || client.name || ''} />
                        </div>
                      </Fade>
                    </li>
                  )
                }

                return null;
              })}
            </ClientsList>
          </ClientsWrapper>
        </Inner>
      </ClientsContainer>
    );
  }
}

export default Clients;
